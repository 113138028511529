import { Flex, Box } from 'components/Box'
import { Text } from 'components/Text'
import { TwitterIcon, MediumIcon, GitBook } from 'components/Svg'
import { LinkContainer } from 'views/Project/components/ProjectBar/styles'
import useTheme from 'hooks/useTheme'
import { FooterWrapper, Line } from './styles'
import EndFooterLinks from './components/EndFooterLinks'
import FooterLinks from './components/FooterLinks'
import useViewport from 'hooks/useViewport'

const FooterLanding = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990
  const IsTab = width <= 1640 && width > 990 // check for Tab screen width

  return (
    <FooterWrapper className='home-footer'>
      <Flex width={'100%'}>
        <Flex
          paddingTop={'35px'}
          paddingBottom={'20px'}
          borderWidth="0px"
          borderTopWidth="0.5px"
          borderStyle="solid"
          borderColor={'#0F1111'}
          maxWidth={'1600px'}
          flexDirection={'column'}
          width={isMobile ? '90%' : '78%'} margin={'auto'} justifyContent={'space-between'}>
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection={'column'}>
              <Text
                fontSize={'16px'}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.medium}
                color={theme.colors.text}
                className="pb-3"
              >
                {'Connect with us'}
              </Text>
              <Flex flexDirection={'row'} mt={'10px'} justifyContent={'space-between'} alignItems={'center'}>
                <LinkContainer className="d-flex" tabIndex={-1}>
                  <a target={'_blank'} href={'https://twitter.com/nexa_network'}>
                    <TwitterIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mr={'13px'} />
                  </a>
                  <a target={'_blank'} href={'https://nexanetwork.gitbook.io/nexa/ '}>
                    <GitBook width={isMobile ? '20px' : '25px'} height={isMobile ? '20px' : '25px'} mx={'13px'} mt={'12px'} />
                  </a>
                  <a target={'_blank'} href={'https://nexa-network.medium.com/'}>
                    <MediumIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mx={'13px'} />
                  </a>
                </LinkContainer>
              </Flex>
            </Flex>

            <Flex width={'100%'} flexDirection="column" className="nexa-img">
              <img width={isMobile ? '150px' : '180px'} alt={''} src="../assets/images/Brandlockup.svg" />
            </Flex>

          </Flex>
          <Line mt={'50px'} />
          <Flex width={'100%'} justifyContent={'space-between'} flexDirection={'row'} mt={'15px'}>
            <Box>
              <Flex className='footerLastLink'>
                <EndFooterLinks text={'Terms'} to="/terms" />
                <EndFooterLinks text={isMobile ? 'Privacy' : 'Privacy policy'} to="/privacy" />
                {/* <EndFooterLinks text={isMobile ?'Cookies' : 'Cookie Policy'} to="/" /> */}
              </Flex>
            </Box>
            <Box>
              <Text
                fontSize={isMobile ? '11px' : '12px'}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                color={theme.colors.textDisabled}
                textAlign={isMobile ? "left" : "right"}
              >
                {'Copyright © 2023 NEXA. All rights reserved'}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </FooterWrapper>
  )
}

export default FooterLanding
