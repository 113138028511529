import styled from 'styled-components'
import { Flex, Box } from '../Box'
import { Nav, Navbar } from 'react-bootstrap'

export const BackgroundContainer = styled(Box)`
  z-index: 999;
  margin:auto;
  width:78%;
  max-width:1600px;
  .risponsive-logo-topbar {
    display:none;
  }
  .navbar {
    padding-top: 25px;
    padding-bottom: 0px
  }
  @media all and (max-width:1640px) {
    width: 88%;
  }
  @media all and (max-width:990px) {
    width: 90%;
    
    .navbar {
      position: absolute;
      left: 0;
      top: 0px;
      padding: 115px 22px 15px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
    height:100vh ;
    justify-content: flex-start;
    background:url(../assets/images/risponsive-nav.png) no-repeat center #000;
    background-size: cover;
    z-index:1;
      display:none;
    }
    .navbar.show-nav {
      display:inline;
    }
    .risponsive-logo-topbar {
      display:flex;
      margin-top:40px;
      z-index:2;
      position:relative;
      img {
        width:130px;
      }
    }
    .navbar-brand {
      display:none;
    }
    nav > div {
      display: flex;
      flex-direction:row;
      padding-left: 0 !important;
      margin:10px 0;
      
    }
    nav > div div {
      
      font-size: 32px;
      font-weight:600;
      line-height:1.2;
      &:not(.shadow) {
        padding-left:0 !important;
      }
    }
    nav > div div[type="button"]{
      margin-top:30px;
    }
    nav .title {
      font-size: 32px;
      font-weight:600;
    }
    nav div button {
      width:160px;
      line-height: 1.2;
    }
    nav div button div {
      display: flex;
      flex-direction:row;
      font-size:14px;
    }
    
  }
  
`

export const RisponsiveNav = styled(Box)`
 display:none;
 @media all and (max-width:990px) {
  display:inline;
  background: url(../assets/images/risponsive-nav-icon.svg) no-repeat center;
  background-size:100%;
  width:40px !important;
  height:40px;
  position: absolute;
  right: 28px;
  top: 47px;
  z-index:2;
  cursor: pointer;
  &.show-nav-icon {
    background: url(../assets/images/close-nav.png) no-repeat center;
    background-size:100%;
  }
`

export const Container = styled(Flex)`
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
`
export const StyledNavbar = styled(Navbar)`
  width: 100%;
  position:relative;
  z-index:9;
  .navbar-style {
  margin-left:-0.3rem
  }
`
export const NavLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.colors.textDisabled} !important;
  margin: 0 1rem;
  font-size: 0.7rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.medium};
  cursor: pointer !important;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }
`
export const NavLinksContainer = styled(Nav)`
  ${({ theme }) => theme.mediaQueries.lg} {
    margin: 0 !important;
  }
`

export const Switch = styled(Flex)`
  background: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 30px;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`

export const SwitchToggle = styled(Flex) <{ checked: boolean }>`
  background: ${({ theme, checked }) => (checked ? theme.colors.primary : 'transparent')};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
`

export const LinkBox = styled(Flex) <{ isHeader?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isHeader }) => !isHeader && '1rem'};
  a {
    text-decoration: none;
    margin-right: ${({ isHeader }) => isHeader && '1.4rem'};
    svg {
      fill: ${({ theme }) => theme.colors.text};
      &:hover {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`

export const LogoImage = styled('img')`
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 80px;
  }
`


export const ProfileStatus = styled(Flex) <{ isReady?: boolean, isAuth?: string }>`
  border-radius: 4px;
  max-width:350px;
  width fit-content;
  height: 44px;
  margin-right: 1rem;
  border: ${({ theme, isAuth }) => isAuth ? `1px solid ${theme.colors.borderColor}` : ''};
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  cursor: pointer;
  .title{
    width: ${({ isReady }) => isReady ? '40%' : 'fit-content'};
    padding: 0.8rem 1rem 0.8rem 1rem;
    margin-right: ${({ isReady }) => isReady ? '0.5rem' : '0px'};
    font-size: 16px;
    font-weight: ${({ theme, isAuth }) => isAuth ? theme.fonts.medium : theme.fonts.regular};
    color: ${({ theme }) => theme.colors.text};
  }
  .shadow{
    padding: 12px 16px;
    margin-right: -1px;
    width: 60%;
    height: 44px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: ${({ theme }) => theme.colors.gradientTab};
    .subtitle{
      background-image: ${({ theme }) => theme.colors.buttonprimary};
      /* Use the text as a mask for the background. */
      /* This will show the gradient as a text color rather than element bg. */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-weight: ${({ theme }) => theme.fonts.medium};
      font-size: 14px;
    }
  }
`
