import { Navbar, NavLink } from 'react-bootstrap'
import { Box, Flex } from '../Box'
import { BackgroundContainer, StyledNavbar, NavLinksContainer, LogoImage, RisponsiveNav } from './styles'
import useTheme from 'hooks/useTheme'
import { Link } from 'react-router-dom'
import { useWalletActionHandlers, useWalletStateHandler } from 'state/wallets/hooks'
import WalletModal from 'components/WalletModal'
import { useModal } from 'widgets/Modal'
import { GitBook, MediumIcon, TwitterIcon } from 'components/Svg'
import truncateHash from 'utils/truncateHash'
import { Text } from '../Text'
import ProfileLink from './component/profileLink'
import { BackButton, BackButtonBox } from 'views/Application/style'
import Button from 'components/Button'
import { useState } from 'react'

import useViewport from 'hooks/useViewport'
import { useUserManager } from 'state/user/hooks'
import { LinkContainer } from 'views/Project/components/ProjectBar/styles'

const HeaderLanding = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990
  const [user] = useUserManager()

  const [showConnectedModal, onDismiss] = useModal(<WalletModal handleDismiss={() => onDismiss()} />, true)
  const [showWalletConnectModal, onDismissConnected] = useModal(
    <WalletModal handleDismiss={() => onDismissConnected()} />,
    true,
  )
  const [showNav, setShowNav] = useState(false)
  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const activeUserAddress = getConnectedWalletAddress()

  useWalletStateHandler()
  const NevShow = () => {
    setShowNav(!showNav)
    if (showNav) {
      document.body.classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
    }
  }

  return (
    <BackgroundContainer className='home-header'>
      <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'} margin={'auto'} mb={'30px'}>
        <Link className="risponsive-logo-topbar" to={'/'} tabIndex={-1} onClick={NevShow}>
          <LogoImage
            src={theme.isDark ? '../assets/images/Brandlockup.svg' : '../assets/images/logo-light.png'}
            alt={'logo'}
          />
        </Link>

        <StyledNavbar className={showNav && 'show-nav'} justifyContent={'space-between'} expand="lg">
          <Navbar.Brand>
            <Link className="navbar-style" to={'/'} tabIndex={-1} onClick={NevShow}>
              <LogoImage
                src={theme.isDark ? '../assets/images/Brandlockup.svg' : '../assets/images/logo-light.png'}
                alt={'logo'}
              />
            </Link>
          </Navbar.Brand>
          {!isMobile &&
            <Flex width={"300px"} justifyContent={'flex-end'}>
              <LinkContainer className="d-flex" tabIndex={-1}>
                <a target={'_blank'} href={'https://twitter.com/nexa_network'}>
                  <TwitterIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mr={'13px'} />
                </a>
                <a target={'_blank'} href={'https://nexanetwork.gitbook.io/nexa/ '}>
                  <GitBook width={isMobile ? '20px' : '25px'} height={isMobile ? '20px' : '25px'} mx={'13px'} mt={'12px'} />
                </a>
                <a target={'_blank'} href={'https://nexa-network.medium.com/'}>
                  <MediumIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mx={'13px'} />
                </a>
              </LinkContainer>
            </Flex>
          }



        </StyledNavbar>
      </Flex>
    </BackgroundContainer>
  )
}

export default HeaderLanding
