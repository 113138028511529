import { Link } from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import useViewport from 'hooks/useViewport'

const EndFooterLinks = ({ text, to }) => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990

  return (
    <Box
      p={'0 5px 0px 0'}
      m={'0 5px 0px 0'}
      borderRadius="0px"
      borderWidth="0px"
      borderRightWidth="0.5px"
      borderStyle="solid"
      borderColor={theme.colors.textDisabled}
      className="cursor-pointer"
    >
      <Link style={{ textDecoration: 'none' }} to={to}>
        <Text
          fontSize={isMobile ? '12px' : '12px'}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.light}
          color={theme.colors.textDisabled}
          textTransform="capitalize"
        >
          {text}
        </Text>
      </Link>
    </Box>
  )
}
export default EndFooterLinks
