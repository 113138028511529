import React from 'react'
import styled from 'styled-components'
import loader from '../assets/images/nexa-loader.png'
import { Box } from './Box'

export const NexaLoaderWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../assets/images/MainScreenBg.png) no-repeat center;
  background-size: 100% 100%;
  max-width: none;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  &.hide-loader {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  img {
    top: calc(50% - 50px);
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
`
export const ImageLoader = styled.img`
    position: absolute;
    left: calc(50% - 50px);
    // transform:translate(-50%, -50%);
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    transition: none !important;
    animation: spin 3s linear infinite;
  @keyframes spin {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
`

const NexaLoader: React.FC = () => {
  return <ImageLoader  src={loader} />
}

export default NexaLoader
