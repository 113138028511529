import React from 'react'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { getLibraryWeb3 } from 'utils/web3React'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ModalProvider } from 'widgets/Modal'
import { LanguageProvider } from 'contexts/Localization'
import { ApolloProvider } from '@apollo/client'
import { SolanaWalletProvider } from 'contexts/Wallets/SolanaWalletContext'
import { getGraphqlClient } from 'utils/getGraphqlClient'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import { Provider } from 'react-redux'
import { ErrorBoundaryWeb3ProviderNetwork } from 'utils/ErrorBoundaryWeb3ProviderNetwork'
import { NetworkContextName } from 'config/constants'
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PusherContextProvider } from 'contexts/pusherContext'

const Providers = ({ children }) => {
  const { client } = getGraphqlClient()
  const store = useStore()

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3ReactProvider getLibrary={getLibraryWeb3}>
          <Web3ProviderNetwork getLibrary={getLibraryWeb3}>
            <ErrorBoundaryWeb3ProviderNetwork>
              <LanguageProvider>
                <HelmetProvider>
                  <ToastContainer limit={1} />
                  <ThemeContextProvider>
                    <RefreshContextProvider>
                      <PusherContextProvider>
                        <ApolloProvider client={client}>
                          <SolanaWalletProvider>
                            <ModalProvider>{children}</ModalProvider>
                          </SolanaWalletProvider>
                        </ApolloProvider>
                      </PusherContextProvider>
                    </RefreshContextProvider>
                  </ThemeContextProvider>
                </HelmetProvider>
              </LanguageProvider>
            </ErrorBoundaryWeb3ProviderNetwork>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </PersistGate>
    </Provider>
  )
}

export default Providers
