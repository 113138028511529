import { Navbar, NavLink } from 'react-bootstrap'
import { Box, Flex } from '../Box'
import { BackgroundContainer, StyledNavbar, NavLinksContainer, LogoImage, RisponsiveNav } from './styles'
import useTheme from 'hooks/useTheme'
import { Link } from 'react-router-dom'
import { useWalletActionHandlers, useWalletStateHandler } from 'state/wallets/hooks'
import WalletModal from 'components/WalletModal'
import { useModal } from 'widgets/Modal'
import { WalletIcon } from 'components/Svg'
import truncateHash from 'utils/truncateHash'
import { Text } from '../Text'
import ProfileLink from './component/profileLink'
import { BackButton, BackButtonBox } from 'views/Application/style'
import Button from 'components/Button'
import { useState } from 'react'

import useViewport from 'hooks/useViewport'
import { useUserManager } from 'state/user/hooks'

const Header = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990
  const [user] = useUserManager()

  const [showConnectedModal, onDismiss] = useModal(<WalletModal handleDismiss={() => onDismiss()} />, true)
  const [showWalletConnectModal, onDismissConnected] = useModal(
    <WalletModal handleDismiss={() => onDismissConnected()} />,
    true,
  )
  const [showNav, setShowNav] = useState(false)
  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const activeUserAddress = getConnectedWalletAddress()

  useWalletStateHandler()
  const NevShow = () => {
    setShowNav(!showNav)
    if (showNav) {
      document.body.classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
    }
  }

  return (
    <BackgroundContainer>
      <Flex width={'100%'} justifyContent={'center'} flexDirection={'column'} margin={'auto'} mb={'30px'}>
        <Link className="risponsive-logo-topbar" to={'/'} tabIndex={-1} onClick={NevShow}>
          <LogoImage
            src={theme.isDark ? '../assets/images/Brandlockup.svg' : '../assets/images/logo-light.png'}
            alt={'logo'}
          />
        </Link>
        <RisponsiveNav className={showNav && 'show-nav-icon'} onClick={NevShow} />
        <StyledNavbar className={showNav && 'show-nav'} expand="lg">
          <Navbar.Brand>
            <Link className="navbar-style" to={'/'} tabIndex={-1} onClick={NevShow}>
              <LogoImage
                src={theme.isDark ? '../assets/images/Brandlockup.svg' : '../assets/images/logo-light.png'}
                alt={'logo'}
              />
            </Link>
          </Navbar.Brand>
          <NavLinksContainer className="ms-3 me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
            {/* <NavLink as={Link} to="/apply">
                  Apply
                </NavLink> */}

            <NavLink as={Link} to="/projects" onClick={NevShow}>
              <Text
                pt={'5px'}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.regular}
                fontSize={isMobile ? '32px' : '16px'}
              >
                Projects
              </Text>
            </NavLink>
          </NavLinksContainer>
          {/* {pathname.slice(0, 10) !== '/projects/' && <SocialLink isHeader={true} />} */}
          <ProfileLink HideNav={NevShow} />
          {user?.crypto_address && activeUserAddress ? (
            <BackButtonBox width={isMobile ? '300px' : '190px'} onClick={NevShow}>
              <BackButton onClick={showConnectedModal} borderRadius={'4px'}>
                <Box className="inner">
                  <WalletIcon mr={'5px'} />
                  <Text fontWeight={theme.fonts.semiBold} fontSize={'16px'} ml={'6px'}>
                    {' '}
                    {truncateHash(user?.crypto_address, 11)}
                  </Text>
                </Box>
              </BackButton>
            </BackButtonBox>
          ) : (
            <Box width={isMobile ? '200px' : '181px'} onClick={NevShow}>
              <Button
                height={'40px'}
                width={'100%'}
                type={'button'}
                variant={'tertiary'}
                onClick={() => {
                  showWalletConnectModal()
                }}
              >
                <Flex justifyContent={'center'}>
                  <WalletIcon iconTheme={'light'} width={'16px'} height={'16px'} />
                  <Text fontWeight={theme.fonts.semiBold} fontSize={'16px'} ml={'6px'}>
                    Connect wallet
                  </Text>
                </Flex>
              </Button>
            </Box>
          )}

          {/* <Switch onClick={toggleTheme}>
                  <SwitchToggle checked={!theme.isDark}>
                    <SunIcon width={20} />
                  </SwitchToggle>
                  <SwitchToggle checked={theme.isDark}>
                    <MoonIcon width={20} />
                  </SwitchToggle>
                </Switch> */}
        </StyledNavbar>
      </Flex>
    </BackgroundContainer>
  )
}

export default Header
