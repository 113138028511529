import { Flex, Box } from 'components/Box'
import { Text } from 'components/Text'
import Button from 'components/Button'
import { TwitterIcon, DiscordIcon, TelegramIcon, MediumIcon, EnvelopIcon } from 'components/Svg'
import { LinkContainer } from 'views/Project/components/ProjectBar/styles'
import { NotifyContainer } from 'views/Landing/styles'
import useTheme from 'hooks/useTheme'
import { FooterWrapper, MainFooter } from './styles'
import EndFooterLinks from './components/EndFooterLinks'
import FooterLinks from './components/FooterLinks'
import useViewport from 'hooks/useViewport'

const Footer = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990
  const IsTab = width <= 1640 && width > 990 // check for Tab screen width

  return (
    <FooterWrapper>
      <Flex maxWidth={'1600px'} width={isMobile ? '90%' : IsTab ? '88%' : '78%'} margin="0 auto">
      <MainFooter>
        <Flex className={'foot-container'} width={'100%'}>
          <Flex flexDirection="column" className={'links-container'}>
            <Box>
              <Text
                fontSize={'16px'}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.medium}
                color={theme.colors.text}
                textTransform="capitalize"
                className="pb-3"
              >
                {'Company'}
              </Text>
              <FooterLinks text={'About'} to="/" />
              <FooterLinks text={'Docs'} to="/" />
              <FooterLinks text={'GitHub'} to="/" />
            </Box>
          </Flex>
          <Flex flexDirection={'column'} flex={2} className="order-top">
            <Text
              fontSize={'16px'}
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.medium}
              color={theme.colors.text}
              textTransform="capitalize"
              className="pb-3"
            >
              {'Connect'}
            </Text>
            <Flex flexDirection={'row'} mt={'20px'} justifyContent={'space-between'} alignItems={'center'}>
              <LinkContainer className="d-flex" tabIndex={-1}>
                <a target={'_blank'} href={'https://google.com'}>
                  <TwitterIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mr={'13px'} />
                </a>
                <a target={'_blank'} href={'https://'}>
                  <TelegramIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mx={'13px'} />
                </a>
                <a target={'_blank'} href={'https://'}>
                  <DiscordIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mx={'13px'} />
                </a>
                <a target={'_blank'} href={'https://'}>
                  <MediumIcon width={isMobile ? '20px' : '23px'} height={isMobile ? '20px' : '23px'} mx={'13px'} />
                </a>
              </LinkContainer>
            </Flex>

            <NotifyContainer>
              <form action="https://app.getresponse.com/add_subscriber.html" acceptCharset="utf-8" method="post">
                <Flex alignItems={'center'} justifyContent={'start'} width={'100%'}>
                  <Flex alignItems={'center'} mr={'0.2rem'}>
                    <EnvelopIcon />
                  </Flex>
                  <input type={'email'} name="email" width={'100%'} required placeholder={'Be the first to know.'} />
                </Flex>
                <input type={'text'} className="hidden" name="campaign_token" value="QRoi3" readOnly />
                <Button height={'36px'} width={'110px'}>
                  <Text className="notify-text">Subscribe</Text>
                </Button>
              </form>
            </NotifyContainer>
          </Flex>
          <Flex flex={1} width={'100%'} flexDirection="column" className="nexa-img">
            <img width={'180px'} alt={''} src="../assets/images/Brandlockup.svg" />
          </Flex>
        </Flex>
      </MainFooter>
      </Flex>
      <Flex
        width={'100%'}
        
        
      >
        <Flex 
        paddingTop={'20px'}
        paddingBottom={'20px'}
        borderWidth="0px"
        borderTopWidth="0.5px"
        borderStyle="solid"
        borderColor={theme.colors.borderFooter}
        maxWidth={'1600px'}
        width={isMobile ? '90%' : IsTab ? '88%' : '78%'} margin={'auto'} justifyContent={'space-between'}>
          <Flex width={'100%'} justifyContent={isMobile ? 'flex-start' : 'space-between'} flexDirection={isMobile ? 'column' : 'row'}>
            <Box>
              <Flex className='footerLastLink'>
                <EndFooterLinks text={'Terms'} to="/terms" />
                <EndFooterLinks text={isMobile ?'Privacy' : 'Privacy policy'} to="/privacy" />
                <EndFooterLinks text={isMobile ?'Cookies' : 'Cookie Policy'} to="/" />
              </Flex>
            </Box>
            <Box mt={isMobile && '10px'}>
              <Text
                fontSize={'12px'}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.light}
                color={theme.colors.textDisabled}
                textAlign={isMobile ? "left" : "right"}
              >
                {'Copyright © 2022 NEXA. All rights reserved'}
              </Text>
            </Box>
          </Flex>
        </Flex>
      
      </Flex>
    </FooterWrapper>
  )
}

export default Footer
