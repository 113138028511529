import { Link } from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import useViewport from 'hooks/useViewport'

const FooterLinks = ({ text, to }) => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const isMobile = width <= 990

  return (
    <Box m="0 4% 0 1%" p={'10px 0 0px 0'} borderRadius="4px" className="cursor-pointer px-0 mx-0 pb-1">
      <Link style={{ textDecoration: 'none' }} to={to}>
        <Text
          fontSize={isMobile ? '14px' : '14px'}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.light}
          color={theme.colors.text}
        >
          {text}
        </Text>
      </Link>
    </Box>
  )
}

export default FooterLinks
