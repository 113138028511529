import { Box, Flex } from 'components/Box'
import styled from 'styled-components'

export const BoxWrapper = styled(Box)`
  width: auto;
  padding: 22px 22px 0;
  cursor: pointer;
`

export const FooterWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.modal};
  width: 100%;
  padding: 3rem 0 0;
  flex-direction: column;
  &.home-footer {
    padding-top:0px;
    background-color:#0F1111;
  }
  .icon-container {
    margin-left: auto;
    .icon {
      font-size: 18px;
    }
  }
  .nexa-img {
    align-items: flex-end;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    &.home-footer {
      padding-top:0px;
    }
    position:relative;
    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon-container {
        margin-left: 0;
        width: 100%;
        .icon {
          font-size: 22px;
        }
      }
    }
    .order-top {
      order:-1;
      & > div:last-child {
        margin-bottom:20px !important;
      }
    }
    .nexa-img {
      position: absolute;
      top: 50px;
      right: 20px;
      width: auto;
    }
  }
`

export const Line = styled(Flex)`
width: 100%;
height: 2px;
border-radius: 12px;
background: var(--additional-divider-black, #1E1E1E);
`

export const MainFooter = styled(Flex)`
  justify-content: center;
  margin: 0 auto 1rem auto;
  width: 100%;
  .links-container {
    flex: 1;
    justify-content: space-between;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    // width: 100%;
    // padding: 1rem;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    // width: 88%;
    .foot-container {
      flex-direction: column;
    }
    .links-container {
      padding: 0;
      margin: 2rem 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    // width: 90%;
    .links-container {
      flex-direction: column;
    }
  }
`
