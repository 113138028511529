import { Box, Flex } from 'components/Box'
import { BlockIcon, PendingIcon, Success } from 'components/Svg'
import { Text } from 'components/Text'
import { useHistory } from 'react-router-dom'
import { useCurrentUser } from 'state/user/selectors'
import { KYC_STATUS } from 'state/user/types'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import { ProfileStatus } from '../styles'
import useTheme from 'hooks/useTheme'
import useViewport from 'hooks/useViewport'

const ProfileLink = ({HideNav}) => {
  const history = useHistory()
  const user = useCurrentUser()
  const { theme } = useTheme()

  const { width } = useViewport()
  const isMobile = width <= 990

  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const activeUserAddress = getConnectedWalletAddress()

  const unVerfiedSet = new Set([KYC_STATUS.UNVALIDATED, KYC_STATUS.REJECTED])
  const isReadyToVerify = unVerfiedSet.has(user?.kyc_verified as KYC_STATUS)
  const isValided = user?.kyc_verified === KYC_STATUS.VALIDATED
  const isSubmitted = user?.kyc_verified === KYC_STATUS.SUBMITTED
  const isBlocked = user?.kyc_verified === KYC_STATUS.FINAL_REJECTED


  return (
    <ProfileStatus
      onClick={() => {
        history.push('/profile')
        HideNav()
      }}
      isReady={isReadyToVerify}
      isAuth={activeUserAddress}
    >
      <Text className="title" >Profile</Text>
      {isReadyToVerify ? (
        <Box className="shadow" ml={isMobile && '10px'} >
          <Text className="subtitle" p={isMobile && '2px 12px !important'} fontSize='16px' fontWeight={theme.fonts.semiBold}>Get Verified</Text>
        </Box>
      ) : (
        <Flex width={'20%'} pr="20px">
          {isValided && <Success />}
          {isSubmitted && <PendingIcon />}
          {isBlocked && <BlockIcon />}
        </Flex>
      )}
    </ProfileStatus>
  )
}

export default ProfileLink
