import React, { lazy, useState } from 'react'
import { Route, Switch, Redirect, useLocation, Router } from 'react-router-dom'

import PageLoader from './components/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ScrollToTop from 'components/ScrollToTop'

import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStyle from './GlobalStyle'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { ROUTES } from 'config/constants/types'
import { ENVIRONMENT } from 'config'
import Privacypolicy from 'views/Privacypolicy'
import FooterLanding from 'components/Footer-landing'
import HeaderLanding from 'components/HeaderLanding'
import { landingPageSet } from 'config/constants'


const ProjectDetails = lazy(() => import('./views/Project'))
const Profile = lazy(() => import('./views/Profile'))
const Projects = lazy(() => import('./views/Projects'))
const Landing = lazy(() => import('./views/Landing'))
const Application = lazy(() => import('./views/Application'))
const Terms = lazy(() => import('./views/Terms'))
const Home = lazy(() => import('./views/Home'))

const App: React.FC = () => {
  const { pathname } = useLocation()
  const env = ENVIRONMENT;

  return (
    <>
      <ScrollToTop />
      <GlobalStyle isShortBg={pathname !== ROUTES.HOME && pathname !== ROUTES.APPLY} isProduction={env === "production" ? true: false} />
      {landingPageSet.has(location.pathname) ? <HeaderLanding /> : <Header /> }   

      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/home" exact>
            <Home />
          </Route>
          <Route path="/apply" exact>
            <Application />
          </Route>
          <Route path="/projects" exact>
            <Projects />
          </Route>
          <Route path="/Projects/:ticker" exact>
            <ProjectDetails />
          </Route>
          <Route path="/profile" exact>
            <Profile />
          </Route>
          <Route path="/terms" exact>
            <Terms/>
          </Route>
          <Route path="/privacy" exact>
            <Privacypolicy/>
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
        {landingPageSet.has(location.pathname) ? <FooterLanding /> : <Footer /> }   

      </SuspenseWithChunkError>
    </>
  )
}

export default App
